<template>

	<v-container fluid style="padding:0px; margin-bottom: -393px;"   class="mobile-size">
		<v-row no-gutters class="text-center" >
			<v-col cols="12" :style="'background-image: url('+require('../../assets/About/About_Desktop_01.jpg')+')'" class="header-mobile backGroundImage" >
				<h1 class="lusciousLifestylesScriptFont aboutLabel-mobile">{{aboutLabel[languageId]}}</h1>
			</v-col>
        </v-row>

		<v-row no-gutters class="text-center">
				<h2 class="lusciousLifestylesScriptFont h2-mobile colorGrey" style="margin-top:-100px; width:100%;">{{helloLabel[languageId]}}</h2>
		</v-row>

		<v-row no-gutters class="text-justify backGroundImage" style="height:fit-content;  padding: 0px 40px 30px 40px;">
			<v-col cols="12" style="padding:0px 20px; display:flex; flex-direction:column; justify-content:space-between;" class="mb-5">
				<h3 class="poppinsSemiboldFont h3-mobile colorYellow">{{presentationLabel[languageId]}}</h3>
				<p class="poppinsExtraLightFont p-mobile colorDarkGrey">{{p1Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-mobile colorDarkGrey">{{p2Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-mobile colorDarkGrey">{{p3Label[languageId]}}</p>
			</v-col>
			<v-col cols="12" style="padding:0px; padding:0px 20px;" class="mt-5 mb-5">
				<img :alt="languageId==0?('Karlus Morales ' + doubleQuotes + 'with Grey Felt Hat' + doubleQuotes + '. Apropiación de Vincent van Gogh Self-Portrait with Grey Felt Hat'):('Karlus Morales ' + doubleQuotes + 'with Grey Felt Hat' + doubleQuotes + '. Appropriation of Vincent van Gogh Self-Portrait with Grey Felt Hat')" src="../../assets/About/Karlus Morales with Grey Felt Hat.jpg"  style="width:100%"/>
			</v-col>
			<v-col cols="12" style="padding:0px 20px; display:flex; flex-direction:column; justify-content:space-between;" class="mt-5">
				<p class="poppinsExtraLightFont p-mobile colorDarkGrey">{{p4Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-mobile colorDarkGrey">{{p5Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-mobile colorDarkGrey">{{p6Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-mobile colorDarkGrey">{{p7Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-mobile colorDarkGrey">{{p8Label[languageId]}}</p>
			</v-col>
		</v-row>
		<v-row no-gutters class="text-justify backGroundImage" :style="'background-image: url('+require('../../assets/About/About_Desktop_03.jpg')+')'" style="height:fit-content;  padding: 70px 40px;">
			<v-col cols="12" style="padding:20px 40px;">
				<img :alt="languageId==0?'Imagen de Descenso al sol por Karlus Morales':'Dive into the sun Image by Karlus Morales'" src="../../assets/About/0Q3A3407_L.jpg"  style="width:100%; aspect-ratio: auto;"/>
			</v-col>
		</v-row>
		<v-row no-gutters class="text-center backGroundImage" :style="'background-image: url('+require('../../assets/About/About_Desktop_04.jpg')+')'" style="height:fit-content; padding-bottom:200px;">
			<v-col cols="12" style="margin-top:70px;" class="mb-8">
				<h3 class="colorWhite poppinsSemiboldFont uppercase" style="font-size:30px;">{{inspirationLabel[languageId]}}</h3>
			</v-col>
			<v-col cols="12" style="padding:0px 20px;" class="mt-8 mb-8">
				<img alt="IconEye" src="../../assets/About/IconEye.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:22px;">{{eyeHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:18px;">{{eyeTextLabel[languageId]}}</p>
			</v-col>

			<v-col cols="12" style="padding:0px 20px;" class="mt-8 mb-8">
				<img alt="IconCamera" src="../../assets/About/IconCamera.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:22px;">{{cameraHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:18px;">{{cameraTextLabel[languageId]}}</p>
			</v-col>

			<v-col cols="12" style="padding:0px 20px;" class="mt-8 mb-8">
				<img alt="IconBird" src="../../assets/About/IconBird.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:22px;">{{birdHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:18px;">{{birdTextLabel[languageId]}}</p>
			</v-col>
		</v-row>
		<v-row no-gutters class="text-center " style="height:fit-content; margin-top:-80px; padding-bottom:50px;">
			<v-col cols="1" style="margin:auto; ">
				<h3 v-if="widthSize>380"  class="verticalText uppercase poppinsRegularFont" style="margin:auto; text-align:center; font-size: 25px;">{{myWorkLabel[languageId]}}</h3>
			</v-col>

			<v-col cols="10" style="height:100%; display:flex; flex-direction:row; justify-content:center;" v-if="dataOk">
				<div class="colorGreen" style="background-color:#414A25; height:536px; width:300px; padding:50px 0px;">
					<div>
						<a class="colorWhite myriadProLightFont noSelect" style="position:relative; top:200px; right:120px; font-size:30px;" @click="backCollections()">
							&#60;
						</a>
						<a class="colorWhite myriadProLightFont noSelect" style="position:relative; top:200px; left:120px; font-size:30px;" @click="forwardCollections()">
							&#62;
						</a>
						<div v-if="galery2 != undefined" style="height:fit-content; width:250px; margin: 10px 20px 10px 20px">
							<img class="imageSize-mobile" :src="require(`../../assets/${image2.base64}`)" :alt="image2.alt.includes('|')?image2.alt.split('|')[languageId]:image2.alt" />
						</div>
						<p v-if="galery2 != undefined" class="colorWhite poppinsLightFont uppercase" style="font-size: 18px;">{{galery2[languageId].replace(/\s/g," ")}}</p>
						<a v-if="galery2 != undefined" class="colorWhite poppinsboldFont uppercase underline" style="font-size: 18px;" @click="selectCollection(galery2[1])">{{shopNow[languageId]}}</a>
					</div>
				</div>
			</v-col>

			<v-col cols="1" style="margin-top: auto; ">
				<div v-if="widthSize>380" class="verticalText uppercase" style="font-size: 20px; margin: auto; height:400px; text-align:center; display:flex; flex-direction:row; justify-content:space-between;">
					<div class="poppinsExtraBoldFont" >{{galeryPage}}</div>
					<hr style="width:20px;transform:rotate(90deg); margin-right:5px;">
					<div class="poppinsRegularFont" v-for="(page,index) in showingPages" :key="index">{{page}}</div>
				</div>
			</v-col>
		</v-row>

        <slot name="footer">

        </slot>

	</v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
    export default {
        name: 'Mobile-About',
        props:{
            goToCollection:{type: Function},
            widthSize:{type: Number}
        },
		data: () => ({
			doubleQuotes:'"',
        }),
        computed:{
            ...mapState("shoppingCart", ["languageId", "privacyPolicyLabel", "termsOfUseLabel", "homeButtonLabel", "artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel", "shopNow"]),
            ...mapState("about", [
                "aboutLabel","helloLabel","presentationLabel","artistLabel","p1Label",
                "p2Label","p3Label","p4Label","p5Label","p6Label","p7Label","p8Label",
                "inspirationLabel","eyeHeaderLabel","cameraHeaderLabel","birdHeaderLabel",
                "eyeTextLabel","cameraTextLabel","birdTextLabel","myWorkLabel",
                "galeryPage","showingPages","galery1","galery2","galery3",
                "image1","image2","image3","collections","pageSize","currentPage",
                "collectionsCount","lastPage","dataOk",
                ]),
        },
        methods:{
            ...mapActions("about", [
                "getCollections", 
                "backCollections", 
                "forwardCollections", 
                ]),
            selectCollection(collection){
                this.$emit('goToCollection',collection);
            }
        }
    }
</script>

<style scoped>
a{
	text-decoration:none;
}
.underline{
	text-decoration:underline;
}
.verticalText{
	writing-mode:vertical-rl; 
	transform: rotate(180deg); 
}
.backGroundImage{
	width:100%; 
	background-size:cover; 
}
.header-mobile{
	background-position-y:top; 
	background-position-x:center; 
	height:400px; 
	display:flex; 
	align-items: center; 
	justify-content: center;
}
.aboutLabel-mobile{
	color: #FEFEFE; 
	font-size:60px; 
	margin-left: 0px;
}
.h2-mobile{
	font-size:130px;
}
.h3-mobile{
	font-size:19px; 
	text-transform: uppercase;
}
.p-mobile{
	font-size:14px;
}
.buttons-mobile{
	font-size:10px;
	text-transform: uppercase;
	color:white;
	width:90px;;
	margin: 0px 5px;
}
.a-mobile{
	color:white;
	font-size:10px;
}
.imageSize-mobile{
	height:300px;
	width:200px;
}
</style>