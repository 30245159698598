<template>
	<div>
		<desktop v-if="isSmallScreen == 'Desktop'"  @goToCollection="goToCollection" >
			<template v-slot:footer>
				<footerComponent :backgroundImage="footerImage"></footerComponent>
			</template>
		</desktop>

		<tablet v-if="isSmallScreen == 'Tablet'"  @goToCollection="goToCollection" >
			<template v-slot:footer>
				<footerComponent :backgroundImage="footerImage"></footerComponent>
			</template>
		</tablet>

		<mobile v-if="isSmallScreen == 'Mobile'"  @goToCollection="goToCollection" :widthSize="widthSize">
			<template v-slot:footer >
				<footerComponent :backgroundImage="footerImage"></footerComponent>
			</template>
		</mobile>
	</div>
</template>

<script>
import footerComponent from './components/Footer'
import desktop from './About/Desktop'
import tablet from './About/Tablet'
import mobile from './About/Mobile'
import { mapState, mapActions } from "vuex";

const backgroundImage = require('../assets/Scapes and Panoramas/IMG_2729_L.webp')

	export default {
		name: 'About',
		components: { footerComponent, desktop, tablet, mobile },
		data: () => ({
			pageTitle:['Acerca de Karlus Morales - Fotógrafo de Naturaleza','About Karlus Morales - Nature Photographer'],
			pageMetaTitle:['Conoce más sobre Karlus Morales, fotógrafo de naturaleza | Venta de fotografías hechas cuadros','Learn more about Karlus Morales, nature photographer | Sale of photographs turned into paintings'],
			pageDescription:['Descubre la pasión de Karlus Morales por la naturaleza a través de sus fotografías hechas cuadros. Reconecta con la naturaleza y haz visible lo invisible ¡Compra las tuyas hoy mismo!','Discover Karlus Morales\' passion for nature through his photographs turned into paintings. Reconnect with nature and make the invisible visible. Buy yours today!'],
            pageKeywords:['Acerca de Karlus Morales, Fotógrafo, Naturaleza, Venta de Fotografías en Línea, Cuadros','About Karlus Morales, Photographer, Nature, Online Sale of Photographs, Paintings'],
            metaImage:'',
			metaImageID:48,
			requiresReload:false,
			footerImage:require('../assets/About/About_Desktop_06.jpg'),
			backgroundImage:backgroundImage,

			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
		}),
		computed:{
            ...mapState("shoppingCart", [
				"languageId", "privacyPolicyLabel", "termsOfUseLabel", "homeButtonLabel", 
				"artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel", "shopNow","baseArtPiecesUrl","images","artPiecesOk",
				]),
			...mapState("about", [
                "aboutLabel","helloLabel","presentationLabel","artistLabel","p1Label",
                "p2Label","p3Label","p4Label","p5Label","p6Label","p7Label","p8Label",
                "inspirationLabel","eyeHeaderLabel","cameraHeaderLabel","birdHeaderLabel",
                "eyeTextLabel","cameraTextLabel","birdTextLabel","myWorkLabel",
                "galeryPage","showingPages","galery1","galery2","galery3",
                "image1","image2","image3","collections","pageSize","currentPage",
                "collectionsCount","lastPage","dataOk",
                ]),
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				this.setMutation({with:screenType,property:'isSmallScreen'})
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
		},
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'index, follow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
		mounted(){
			if(this.artPiecesOk){
				this.metaImage = this.selectMetaImage(this.metaImageID);
			} else {
				this.requiresReload = true;
			}
			this.getCollections(this.currentPage);
		},
		methods:{
            ...mapActions("shoppingCart", ["filter", ]),
            ...mapActions("about", [
				"setMutation",
                "getCollections", 
                "backCollections", 
                "forwardCollections", 
                ]),
			goToCollection(collection){
				var idCollection = this.collections.find(x=>x.name.includes(collection)).id;
                this.filter({property:"collectionFilter",with:idCollection});
				this.$router.push(`/${this.baseArtPiecesUrl[this.languageId]}`);
			},
			getImageById(idImage){
				if(this.images != null){
					let image = this.images.find(x=>x.id == idImage)
					return image.base64
				}
			},
			selectMetaImage(idImage){
				idImage = idImage!=null?idImage:this.metaImageID;
				let mImage = this.getImageById(idImage)
				if(mImage != null){
					return window.location.origin + require(`../assets/${mImage}`)
				}
			},		
		},
		updated(){
			if(this.requiresReload && this.artPiecesOk){
				this.metaImage = this.selectMetaImage(this.metaImageID);
				this.requiresReload = false;
			}
		}
	}
</script>