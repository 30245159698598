<template>
	<v-container fluid style="padding:0px; margin-bottom: -393px;" class="desktop-size">
		<v-row no-gutters class="text-center" style="margin-top:-128px;">
			<v-col cols="12" :style="'background-image: url('+require('../../assets/About/About_Desktop_01.jpg')+')'" class="header backGroundImage" >
				<h1 class="lusciousLifestylesScriptFont aboutLabel">{{aboutLabel[languageId]}}</h1>
			</v-col>
        </v-row>

		<v-row no-gutters class="text-justify">
				<h2 class="lusciousLifestylesScriptFont h2 colorGrey" style="margin-top:-265px;">{{helloLabel[languageId]}}</h2>
		</v-row>

		<v-row no-gutters class="text-justify backGroundImage" style="height:fit-content; padding-bottom:150px;">
			<v-col cols="1"></v-col>

			<v-col cols="5" style="padding:0px 20px; display:flex; flex-direction:column; justify-content:space-between;">
				<h3 class="poppinsSemiboldFont h3 colorYellow">{{presentationLabel[languageId]}}</h3>
				<p class="poppinsExtraLightFont p colorDarkGrey">{{p1Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p colorDarkGrey">{{p2Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p colorDarkGrey">{{p3Label[languageId]}}</p>
			</v-col>

			<v-col cols="5" style="padding:0px; padding-left:20px; text-align:center;">
				<img :alt="languageId==0?('Karlus Morales ' + doubleQuotes + 'with Grey Felt Hat' + doubleQuotes + '. Apropiación de Vincent van Gogh Self-Portrait with Grey Felt Hat'):('Karlus Morales ' + doubleQuotes + 'with Grey Felt Hat' + doubleQuotes + '. Appropriation of Vincent van Gogh Self-Portrait with Grey Felt Hat')" src="../../assets/About/Karlus Morales with Grey Felt Hat.jpg"  style="width:600px"/>
			</v-col>

			<v-col cols="1" >
				<p class="poppinsSemiboldFont verticalText uppercase" style="color:#4C4C4C; font-size:35px; height:800px; text-align:center;">{{artistLabel[languageId]}}</p>
			</v-col>
		</v-row>
		<v-row no-gutters class="text-justify backGroundImage" :style="'background-image: url('+require('../../assets/About/About_Desktop_03.jpg')+')'" style="height:fit-content; padding-top:170px; padding-bottom:200px;">
			<v-col cols="1"></v-col>

			<v-col cols="5" style="padding:0px 20px; text-align:center;">
				<img :alt="languageId==0?'Imagen de Descenso al sol por Karlus Morales':'Dive into the sun Image by Karlus Morales'" src="../../assets/About/0Q3A3407_L.jpg"  style="width:600px; aspect-ratio: auto;"/>
			</v-col>

			<v-col cols="5" style="padding:0px 20px; display:flex; flex-direction:column; justify-content:space-between;">
				<p class="poppinsExtraLightFont p colorDarkGrey">{{p4Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p colorDarkGrey">{{p5Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p colorDarkGrey">{{p6Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p colorDarkGrey">{{p7Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p colorDarkGrey">{{p8Label[languageId]}}</p>
			</v-col>

			<v-col cols="1"></v-col>
		</v-row>
		<v-row no-gutters class="text-center backGroundImage" :style="'background-image: url('+require('../../assets/About/About_Desktop_04.jpg')+')'" style="height:974px;">
			<v-col cols="12" style="margin-top:150px;">
				<h3 class="colorWhite poppinsSemiboldFont uppercase" style="font-size:49px;">{{inspirationLabel[languageId]}}</h3>
			</v-col>
			<v-col cols="4" style="padding:0px 20px;">
				<img alt="IconEye" src="../../assets/About/IconEye.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:35px;">{{eyeHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:26px;">{{eyeTextLabel[languageId]}}</p>
			</v-col>

			<v-col cols="4" style="padding:0px 20px;">
				<img alt="IconCamera" src="../../assets/About/IconCamera.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:35px;">{{cameraHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:26px;">{{cameraTextLabel[languageId]}}</p>
			</v-col>

			<v-col cols="4" style="padding:0px 20px;">
				<img alt="IconBird" src="../../assets/About/IconBird.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:35px;">{{birdHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:26px;">{{birdTextLabel[languageId]}}</p>
			</v-col>
		</v-row>
		<v-row no-gutters class="text-center backGroundImage" :style="'background-image: url('+require('../../assets/About/About_Desktop_05.jpg')+')'" style="height:1207px;">
			<v-col cols="1" style="height:100%">
				<h3 class="verticalText uppercase poppinsRegularFont" style="margin:auto; height:100%; text-align:center; font-size: 40px;">{{myWorkLabel[languageId]}}</h3>
			</v-col>

			<v-col cols="10" style="height:100%; padding-top:125px; display:flex; flex-direction:row;" v-if="dataOk">
				<div class="colorGreen" style="background-color:#414A25; height:944px; width:588px;">
					<div v-if="galery1 != undefined">
						<a class="colorWhite myriadProLightFont noSelect" style="position:relative; top:400px; right:220px; font-size:65px;" @click="backCollections()">
							&#60;
						</a>
						<div style="height:507px; width:338px; margin: 53px 70px 90px 135px;">
							<img class="imageSize" :src="require(`../../assets/${image1.base64}`)" :alt="image1.alt.includes('|')?image1.alt.split('|')[languageId]:image1.alt" />
						</div>
						<p class="colorWhite poppinsLightFont uppercase" style="font-size: 30px;">{{galery1[languageId]}}</p>
						<a class="colorWhite poppinsboldFont uppercase underline" style="font-size: 23px;" @click="selectCollection(galery1[1])">{{shopNow[languageId]}}</a>
					</div>
				</div>
				<div style="background-color:#483729; height:944px; width:553px;">
					<div v-if="galery2 != undefined">
						<div style="height:507px; width:338px; margin: 150px 70px 90px 70px;">
							<img class="imageSize" :src="require(`../../assets/${image2.base64}`)" :alt="image2.alt.includes('|')?image2.alt.split('|')[languageId]:image2.alt" />
						</div>
						<p class="colorWhite poppinsLightFont uppercase" style="font-size: 30px;">{{galery2[languageId]}}</p>
						<a class="colorWhite poppinsboldFont uppercase underline" style="font-size: 23px;" @click="selectCollection(galery2[1])">{{shopNow[languageId]}}</a>
					</div>
				</div>
				<div class="colorYellow" style="background-color:#D79A2B; height:944px; width:588px;">
					<div v-if="galery3 != undefined">
						<a class="colorWhite myriadProLightFont noSelect" style="position:relative; top:400px; left:220px; font-size:65px;" @click="forwardCollections()">
							&#62;
						</a>
						<div style="height:507px; width:338px; margin: 53px 135px 90px 70px;">
							<img class="imageSize" :src="require(`../../assets/${image3.base64}`)" :alt="image3.alt.includes('|')?image3.alt.split('|')[languageId]:image3.alt" />
						</div>
						<p class="colorWhite poppinsLightFont uppercase" style="font-size: 30px;">{{galery3[languageId]}}</p>
						<a class="colorWhite poppinsboldFont uppercase underline" style="font-size: 23px;" @click="selectCollection(galery3[1])">{{shopNow[languageId]}}</a>
					</div>
				</div>
			</v-col>

			<v-col cols="1" style="margin: 475px 0px; ">
				<div class="verticalText uppercase" style="font-size: 26px; margin: auto; height:257px; text-align:center; display:flex; flex-direction:row; justify-content:space-between;">
					<div class="poppinsExtraBoldFont" >{{galeryPage}}</div>
					<hr style="width:45px;transform:rotate(90deg); margin-right:-2px;">
					<div class="poppinsRegularFont" v-for="(page,index) in showingPages" :key="index">{{page}}</div>
				</div>
			</v-col>
		</v-row>

        <slot name="footer">

        </slot>
		
		
	</v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
    export default {
        name: 'Desktop-About',
        props:{
            goToCollection:{type: Function}
        },
		data: () => ({
			doubleQuotes:'"',
        }),
        computed:{
            ...mapState("shoppingCart", ["languageId", "privacyPolicyLabel", "termsOfUseLabel", "homeButtonLabel", "artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel", "shopNow"]),
            ...mapState("about", [
                "aboutLabel","helloLabel","presentationLabel","artistLabel","p1Label",
                "p2Label","p3Label","p4Label","p5Label","p6Label","p7Label","p8Label",
                "inspirationLabel","eyeHeaderLabel","cameraHeaderLabel","birdHeaderLabel",
                "eyeTextLabel","cameraTextLabel","birdTextLabel","myWorkLabel",
                "galeryPage","showingPages","galery1","galery2","galery3",
                "image1","image2","image3","collections","pageSize","currentPage",
                "collectionsCount","lastPage","dataOk",
                ]),
        },
        methods:{
            ...mapActions("about", [
                "getCollections", 
                "backCollections", 
                "forwardCollections", 
                ]),
            selectCollection(collection){
                this.$emit('goToCollection',collection);
            }
        }
    }
</script>

<style scoped>
a{
	text-decoration:none;
}
.underline{
	text-decoration:underline;
}
.verticalText{
	writing-mode:vertical-rl; 
	transform: rotate(180deg); 
}
.backGroundImage{
	width:100%; 
	background-size:cover; 
}
.header{
	background-position-y:top; 
	height:1014px; 
	display:flex; 
	align-items: flex-end; 
	justify-content: center;
}
.aboutLabel{
	color: #FEFEFE; 
	padding-bottom:400px; 
	font-size:185px; 
	margin-left: 0px;
}
.h2{
	font-size:377px;
}
.h3{
	font-size:37px; 
	text-transform: uppercase;
}
.p{
	font-size:24px;
}
.buttons{
	font-size:10px;
	text-transform: uppercase;
	color:white;
	width:90px;;
	margin: 0px 5px;
}
.a{
	color:white;
	font-size:10px;
}
.imageSize{
	height:507px;
	width:338px;
}
</style>