<template>

	<v-container fluid style="padding:0px; margin-bottom: -393px;"  class="tablet-size">
		<v-row no-gutters class="text-center" style="margin-top:-128px;">
			<v-col cols="12" :style="'background-image: url('+require('../../assets/About/About_Desktop_01.jpg')+')'" class="header-tablet backGroundImage" >
				<h1 class="lusciousLifestylesScriptFont aboutLabel-tablet">{{aboutLabel[languageId]}}</h1>
			</v-col>
        </v-row>

		<v-row no-gutters class="text-justify">
			<v-col cols="1"></v-col>
			<v-col cols="10">
				<h2 class="lusciousLifestylesScriptFont h2-tablet colorGrey" style="margin-top:-150px;">{{helloLabel[languageId]}}</h2>
			</v-col>
			<v-col cols="1"></v-col>
		</v-row>

		<v-row no-gutters class="text-justify backGroundImage" style="height:100%; padding-bottom:80px;">
			<v-col cols="1"></v-col>

			<v-col cols="5" style="padding:0px 20px; display:flex; flex-direction:column; margin:auto;">
				<h3 class="poppinsSemiboldFont h3-tablet colorYellow mb-4">{{presentationLabel[languageId]}}</h3>
				<p class="poppinsExtraLightFont p-tablet colorDarkGrey">{{p1Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-tablet colorDarkGrey">{{p2Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-tablet colorDarkGrey">{{p3Label[languageId]}}</p>
			</v-col>

			<v-col cols="5" style="padding:0px; padding-left:20px; text-align:center; margin:auto;">
				<img :alt="languageId==0?('Karlus Morales ' + doubleQuotes + 'with Grey Felt Hat' + doubleQuotes + '. Apropiación de Vincent van Gogh Self-Portrait with Grey Felt Hat'):('Karlus Morales ' + doubleQuotes + 'with Grey Felt Hat' + doubleQuotes + '. Appropriation of Vincent van Gogh Self-Portrait with Grey Felt Hat')" src="../../assets/About/Karlus Morales with Grey Felt Hat.jpg"  style="aspect-ratio: auto;" class="sideImageSize-tablet"/>
			</v-col>

			<v-col cols="1" >
			</v-col>
		</v-row>
		<v-row no-gutters class="text-justify backGroundImage" :style="'background-image: url('+require('../../assets/About/About_Desktop_03.jpg')+')'" style="height:100%; padding-top:70px; padding-bottom:80px;">
			<v-col cols="1"></v-col>

			<v-col cols="5" style="padding:0px 20px; text-align:center; margin:auto;">
				<img :alt="languageId==0?'Imagen de Descenso al sol por Karlus Morales':'Dive into the sun Image by Karlus Morales'" src="../../assets/About/0Q3A3407_L.jpg"  style="aspect-ratio: auto;" class="sideImageSize-tablet"/>
			</v-col>

			<v-col cols="5" style="padding:0px 20px; display:flex; flex-direction:column; margin:auto;">
				<p class="poppinsExtraLightFont p-tablet colorDarkGrey">{{p4Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-tablet colorDarkGrey">{{p5Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-tablet colorDarkGrey">{{p6Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-tablet colorDarkGrey">{{p7Label[languageId]}}</p>
				<p class="poppinsExtraLightFont p-tablet colorDarkGrey">{{p8Label[languageId]}}</p>
			</v-col>

			<v-col cols="1"></v-col>
		</v-row>
		<v-row no-gutters class="text-center backGroundImage" :style="'background-image: url('+require('../../assets/About/About_Desktop_04.jpg')+')'" style="height:100%; padding-bottom:150px;">
			<v-col cols="12" style="margin-top:70px; margin-bottom:70px;">
				<h3 class="colorWhite poppinsSemiboldFont uppercase" style="font-size:40px;">{{inspirationLabel[languageId]}}</h3>
			</v-col>
			<v-col cols="4" style="padding:0px 20px;">
				<img alt="IconEye" src="../../assets/About/IconEye.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:25px;">{{eyeHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:20px;">{{eyeTextLabel[languageId]}}</p>
			</v-col>

			<v-col cols="4" style="padding:0px 20px;">
				<img alt="IconCamera" src="../../assets/About/IconCamera.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:25px;">{{cameraHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:20px;">{{cameraTextLabel[languageId]}}</p>
			</v-col>

			<v-col cols="4" style="padding:0px 20px;">
				<img alt="IconBird" src="../../assets/About/IconBird.png"  style="aspect-ratio: auto;"/>
				<h4 class="colorWhite poppinsMediumFont uppercase" style="font-size:25px;">{{birdHeaderLabel[languageId]}}</h4>
				<p class="colorWhite poppinsLightFont" style="font-size:20px;">{{birdTextLabel[languageId]}}</p>
			</v-col>
		</v-row>
		<v-row no-gutters class="text-center backGroundImage" :style="'background-image: url('+require('../../assets/About/About_Desktop_05.jpg')+')'" style="height:100%;">
			<v-col cols="1" style="margin:auto; height:700px;">
				<h3 class="verticalText uppercase poppinsRegularFont" style="margin:auto; height:100%; text-align:center; font-size: 38px;">{{myWorkLabel[languageId]}}</h3>
			</v-col>

			<v-col cols="10" style="height:100%; display:flex; flex-direction:row; justify-content:center; margin-top:-100px; " v-if="dataOk">
				<div v-if="galery1 != undefined" class="colorGreen" style="background-color:#414A25; height:700px; width:420px; padding-bottom:60px;">
					<div >
						<a class="colorWhite myriadProLightFont noSelect" style="position:relative; top:300px; right:180px; font-size:45px;" @click="backCollections()">
							&#60;
						</a>
						<div style="height:fit-content; width:350px; margin: auto; padding-left:20px;">
							<img class="imageSize-tablet" :src="require(`../../assets/${image1.base64}`)" :alt="image1.alt.includes('|')?image1.alt.split('|')[languageId]:image1.alt" />
						</div>
						<p class="colorWhite poppinsLightFont uppercase" style="font-size: 24px; padding-top:40px;">{{galery1[languageId].replace(/\s/g," ")}}</p>
						<a class="colorWhite poppinsboldFont uppercase underline" style="font-size: 20px;" @click="selectCollection(galery1[1])">{{shopNow[languageId]}}</a>
					</div>
				</div>
				<div v-if="galery2 != undefined" class="colorYellow" style="background-color:#D79A2B; height:700px; width:840px; padding-bottom:60px;">
					<div >
						<a class="colorWhite myriadProLightFont noSelect" style="position:relative; top:300px; right:370px; font-size:45px;" @click="backCollections()">
							&#60;
						</a>
						<a class="colorWhite myriadProLightFont noSelect" style="position:relative; top:300px; left:370px; font-size:45px;" @click="forwardCollections()">
							&#62;
						</a>
						<div style="height:fit-content; width:350px; margin: auto; padding-right:20px;">
							<img class="imageSize-tablet" :src="require(`../../assets/${image2.base64}`)" :alt="image2.alt.includes('|')?image2.alt.split('|')[languageId]:image2.alt" />
						</div>
						<p class="colorWhite poppinsLightFont uppercase" style="font-size: 24px; padding-top:40px;">{{galery2[languageId].replace(/\s/g," ")}}</p>
						<a class="colorWhite poppinsboldFont uppercase underline" style="font-size: 20px;" @click="selectCollection(galery2[1])">{{shopNow[languageId]}}</a>
					</div>
				</div>
				<div v-if="galery3 != undefined" class="colorYellow" style="background-color:#483729; height:700px; width:420px; padding-bottom:60px;">
					<div >
						<a class="colorWhite myriadProLightFont noSelect" style="position:relative; top:300px; left:180px; font-size:45px;" @click="forwardCollections()">
							&#62;
						</a>
						<div style="height:fit-content; width:350px; margin: auto; padding-right:20px;">
							<img class="imageSize-tablet" :src="require(`../../assets/${image3.base64}`)" :alt="image3.alt.includes('|')?image3.alt.split('|')[languageId]:image3.alt" />
						</div>
						<p class="colorWhite poppinsLightFont uppercase" style="font-size: 24px; padding-top:40px;">{{galery3[languageId].replace(/\s/g," ")}}</p>
						<a class="colorWhite poppinsboldFont uppercase underline" style="font-size: 20px;" @click="selectCollection(galery3[1])">{{shopNow[languageId]}}</a>
					</div>
				</div>
			</v-col>

			<v-col cols="1" style="margin:auto; height:350px; margin-top:260px;">
				<div class="verticalText uppercase" style="font-size: 26px; margin: auto; height:340px; text-align:center; display:flex; flex-direction:row; justify-content:space-between;">
					<div class="poppinsExtraBoldFont" >{{galeryPage}}</div>
					<hr style="width:45px;transform:rotate(90deg); margin-right:-2px;">
					<div class="poppinsRegularFont" v-for="(page,index) in showingPages" :key="index">{{page}}</div>
				</div>
			</v-col>
		</v-row>

        <slot name="footer">

        </slot>
		
	</v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
    export default {
        name: 'Tablet-About',
        props:{
            goToCollection:{type: Function}
        },
		data: () => ({
			doubleQuotes:'"',
        }),
        computed:{
            ...mapState("shoppingCart", ["languageId", "privacyPolicyLabel", "termsOfUseLabel", "homeButtonLabel", "artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel", "shopNow"]),
            ...mapState("about", [
                "aboutLabel","helloLabel","presentationLabel","artistLabel","p1Label",
                "p2Label","p3Label","p4Label","p5Label","p6Label","p7Label","p8Label",
                "inspirationLabel","eyeHeaderLabel","cameraHeaderLabel","birdHeaderLabel",
                "eyeTextLabel","cameraTextLabel","birdTextLabel","myWorkLabel",
                "galeryPage","showingPages","galery1","galery2","galery3",
                "image1","image2","image3","collections","pageSize","currentPage",
                "collectionsCount","lastPage","dataOk",
                ]),
        },
        methods:{
            ...mapActions("about", [
                "getCollections", 
                "backCollections", 
                "forwardCollections", 
                ]),
            selectCollection(collection){
                this.$emit('goToCollection',collection);
            }
        }
    }
</script>

<style scoped>
a{
	text-decoration:none;
}
.underline{
	text-decoration:underline;
}
.verticalText{
	writing-mode:vertical-rl; 
	transform: rotate(180deg); 
}
.backGroundImage{
	width:100%; 
	background-size:cover; 
}
.header-tablet{
	background-position-y:top; 
	background-position-x:center; 
	height:800px; 
	display:flex; 
	align-items: center; 
	justify-content: center;
}
.aboutLabel-tablet{
	color: #FEFEFE; 
	font-size:144px; 
	margin-left: 0px;
}
.h2-tablet{
	font-size:200px;
}
.h3-tablet{
	font-size:30px; 
	text-transform: uppercase;
}
.p-tablet{
	font-size:16px;
}
.buttons-tablet{
	font-size:10px;
	text-transform: uppercase;
	color:white;
	width:90px;;
	margin: 0px 5px;
}
.a-tablet{
	color:white;
	font-size:10px;
}
.imageSize-tablet{
	height:450px;
	width:320px;
}
.sideImageSize-tablet{
	width:406px;
}
</style>